/**
 * Pesos calculados de bolsa para determinados productos por código
 */
export const WeightProduct = [
    //CHICHARRON
    {
        code: 33, weight:0.100 
    },
    //Diablito 
    {
        code: 9, weight:0.400 
    },
    //manteca
    {
        code: 34, weight:0.680
    },

      //YOGURT
    {
        code: 77, weight:0.520
    },

       //QUESO DE MANO
    {
        code: 8, weight:0.640
    },

         //QUESO GUAYANES
    {
        code: 39, weight:0.640
    }


];