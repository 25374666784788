//Tipos de salidas 
module.exports = {

    cutOutCode: {
        //Recortes de jamón
        1:31,//'AREPERO',
        2:31,//'ESPALDA',
        3:31,//'PIERNA',
        4:31,//'FIAMBRE',
        5:31,//'PASTEL CUADRADO',
        6:31,//'PASTEL DE CARNE REDONDO',
        55:31,//mozzarella',
        56:31,//'JAMON AHUMADO'
        80:31,//'BOLOGNA'

        //Recortes de ahumados
        10:32,//'TOCINETA AHUMADA',
        13:32,//'CHULETA AHUMADA',
        18:32,//'LOMO CANADIENSE',
        19:32,//'LOMO TIPO YORK',
        46:32,//'PERNIL AHUMADO'
        48:32,//'LOMITO AHUMADO'
        15:32,//'QUESO AMARILLO CAMPORICO'

    },

}