export const boxConstants = {
    //Crear
    BOX_CREATE_REQUEST: 'BOX_CREATE_REQUEST',
    BOX_CREATE_SUCCESS: 'BOX_CREATE_SUCCESS',
    BOX_CREATE_FAILURE: 'BOX_CREATE_FAILURE',

    BOX_WITHDRAWAL_REQUEST: 'BOX_WITHDRAWAL_REQUEST',
    BOX_WITHDRAWAL_SUCCESS: 'BOX_WITHDRAWAL_SUCCESS',
    BOX_WITHDRAWAL_FAILURE: 'BOX_WITHDRAWAL_FAILURE',

    BOX_TABLE_REQUEST: 'BOX_TABLE_REQUEST',
    BOX_TABLE_SUCCESS: 'BOX_TABLE_SUCCESS',
    BOX_TABLE_FAILURE: 'BOX_TABLE_FAILURE',

    BOX_TABLE_DETAIL_REQUEST: 'BOX_TABLE_DETAIL_REQUEST',
    BOX_TABLE_DETAIL_SUCCESS: 'BOX_TABLE_DETAIL_SUCCESS',
    BOX_TABLE_DETAIL_FAILURE: 'BOX_TABLE_DETAIL_FAILURE',

    BOX_CLOSING_REQUEST: 'BOX_CLOSING_REQUEST',
    BOX_CLOSING_SUCCESS: 'BOX_CLOSING_SUCCESS',
    BOX_CLOSING_FAILURE: 'BOX_CLOSING_FAILURE',

    BOX_CORRECTION_REQUEST: 'BOX_CORRECTION_REQUEST',
    BOX_CORRECTION_SUCCESS: 'BOX_CORRECTION_SUCCESS',
    BOX_CORRECTION_FAILURE: 'BOX_CORRECTION_FAILURE',
};
